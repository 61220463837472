import React from 'react';
import { Link } from 'gatsby';

import Layout from '../../components/Layout';
import CFS from '../../components/molecules/CFS';
import imgSecSection from '../../assets/images/pages/acreditaciones-y-asociaciones/secondSec.png';
import imgFirstSection from '../../assets/images/pages/acreditaciones-y-asociaciones/firstSec.png';
import imgThirdSection from '../../assets/images/pages/acreditaciones-y-asociaciones/home-2.webp';
import imgPartner1 from '../../assets/images/pages/acreditaciones-y-asociaciones/partner1.png';
import imgPartner2 from '../../assets/images/pages/acreditaciones-y-asociaciones/partner2.png';
import imgPartner3 from '../../assets/images/pages/acreditaciones-y-asociaciones/partner3.png';
import imgPartner4 from '../../assets/images/pages/acreditaciones-y-asociaciones/partner4.png';

import '../../assets/styles/pages/acreditaciones-y-asociaciones.scss';

const AcrediracionesYAsociacioner = () => (
  <Layout
    title="Acreditaciones y Asociaciones | ELE Barcelona"
    description="Estamos respaldados por escuelas internacionales como TEFL Iberia y Languages4Life que hace que el certificado de ELE Barcelona sea reconocido y respetado."
  >
    <div className="acreditaciones-y-asociaciones">
      <CFS
        title="Acreditaciones y asociaciones"
        description="ELE Barcelona está asociada a importantes organismos profesionales de la enseñanza del español como lengua extranjera como los siguientes:"
      />
      <section className="second-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="left">
                <h3 className="left__title c-title-34">ASELE</h3>
                <p className="left__description c-text-15">
                  <span className="c-text-15 c-text-15--gray-100">
                    (Asociación para la Enseñanza del Español como Lengua Extranjera)
                  </span>
                  <br />
                  <br />
                  ASELE es una organización internacional que cuenta con más de ochocientos
                  profesores, estudiantes y profesionales de alrededor de cincuenta países,
                  dedicados a investigar y enseñar el español como LE / L2.
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right">
                <img className="cta-img" src={imgFirstSection} alt="imgCTA" />
                <div className="overlap">
                  <img src={imgPartner1} alt="imgPartner1" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="third-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="left">
                <img src={imgSecSection} alt="Happy person" />
                <div className="overlap">
                  <img src={imgPartner2} alt="imgPartner2" />
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right">
                <h3 className="right__title c-title-34">AEPE</h3>
                <p className="right__description c-text-15">
                  <span className="c-text-15 c-text-15--gray-100">
                    (Asociación Europea de Profesores de Español)
                  </span>
                  <br />
                  <br />
                  AEPE fue creada en 1968 en la Universidad Internacional Menéndez Pelayo de
                  Santander. La Asociación está compuesta por profesores de español de unos 40
                  países, no sólo europeos, sino también de América y Asia.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="fourth-section c-section-py-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <div className="left">
                <h3 className="left__title c-title-34">Respaldos y afiliaciones</h3>
                <p className="left__description c-text-15">
                  ELE Barcelona está respaldada por reconocidas escuelas internacionales como Languages4Life. Languages4Life es una de las academias de idiomas más reputadas de Barcelona con más de 12 años de experiencia en la enseñanza y aprendizaje de lenguas extranjeras en la ciudad de Barcelona.
                  <br />
                  <br />
                  <span className="c-text-15 c-text-15--gray-100">
                    Hemos logrado dichas asociaciones manteniendo estándares excepcionalmente altos
                    en nuestros cursos y un enfoque muy profesional en el funcionamiento de la
                    escuela. Aseguramos que la escuela continúe manteniendo tal reconocimiento
                    colaborando de cerca con escuelas locales e internacionales.
                  </span>
                </p>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="right">
                <img src={imgThirdSection} alt="Happy person" />
                <div className="overlap overlap--top">
                  <img src={imgPartner3} alt="imgPartner3" />
                </div>
                {/* <div className="overlap overlap--bot">
                  <img src={imgPartner4} alt="imgPartner4" />
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  </Layout>
);

export default AcrediracionesYAsociacioner;
